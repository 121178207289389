body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.react-datepicker-wrapper {
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
  border: 1px solid #fff;
  border-radius: 100px;
  background-color: #fff;
  height: 40px;
  padding: 0px 20px 0px 20px !important;

}

.react-datepicker-wrapper>div>input {
  height: 40px;
  border: 0px;

}

.react-datepicker-wrapper>div>input:focus {
  outline: none;

}

.chart {
  width: 100%;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.lebelButton {
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid gray;
  padding: 5px;
  border-radius:5px;

}

.lebelButton-focus {
  background-color: rgb(157, 112, 228);
  border: 2px solid rgb(17, 9, 58);
  color: white;
  padding: 5px;
  border-radius:5px;

}